<template>
  <div class="form-rpp">
    <div class="text-center mb-3">
      <a-radio-group size="large" v-model="radioMenu" button-style="solid">
        <a-radio-button value="detail">
          Detail
        </a-radio-button>
        <a-radio-button value="grading">
          Grading
        </a-radio-button>
        <a-radio-button value="activity">
          Activity
        </a-radio-button>
        <a-radio-button value="attachment">
          Attachment
        </a-radio-button>
      </a-radio-group>
    </div>
    <a-form-model v-show="radioMenu !== 'activity'" :model="propData" ref="ruleForm" :rules="rules">
      <template v-if="radioMenu === 'detail'">
        <a-row :gutter="16">
          <a-col :sm="24" :md="12">
            <a-form-model-item class="text" label="Subject">
              <a-select disabled style="min-width: 150px" class="mr-3" :value="currentSubject" size="large">
                <a-select-option v-for="subject in subjects" :value="subject.id" :key="subject.id">{{subject.nama}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :sm="24" :md="12">
            <a-form-model-item label="Level">
              <a-select style="min-width: 150px" disabled class="mr-3" :value="currentLevel" size="large">
                <a-select-option v-for="level in levels" :value="level.id" :key="level.id">{{level.nama}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-model-item class="hsn-input-required" label="Week" prop="id_week" required>
              <a-input-number :disabled="isReadOnly" :value="currentWeek" @change="e => handleChange(e, 'id_week')" :min="1" :max="weeks.length" class="w-100" size="large"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="16">
            <a-form-model-item label="Range Date">
              <a-range-picker
                disabled
                size="large"
                class="w-100"
                format="DD MMMM YYYY"
                v-model="weekDate"
              />
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="8">
            <a-form-model-item label="Start At">
              <a-date-picker v-model="weekDate.startAt" disabled class="w-100" size="large"></a-date-picker>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="End At">
              <a-date-picker v-model="weekDate.endAt" disabled class="w-100" size="large"></a-date-picker>
            </a-form-model-item>
          </a-col> -->
        </a-row>
        <a-form-model-item class="hsn-input-required" label="Syar'i Source" prop="landasan_syar_i">
          <a-input :disabled="isReadOnly" v-model="propData.landasan_syar_i" size="large"></a-input>
        </a-form-model-item>
        <a-form-model-item class="hsn-input-required" label="Learning Objectives" prop="tujuan_pembelajaran">
          <ckeditor
            :disabled="isReadOnly"
            :value="propData.tujuan_pembelajaran"
            @input="e => handleChange(e, 'tujuan_pembelajaran')"
            :editor="editor"
            :config="editorConfig"
        ></ckeditor>
        </a-form-model-item>
        <a-form-model-item class="hsn-input-required" label="Learning Sources" prop="sumber_belajar">
          <!-- <ckeditor
            :value="propData.sumber_belajar"
            @input="e => handleChange(e, 'sumber_belajar')"
            :editor="editor"
            :config="editorConfig"
        ></ckeditor> -->
        <a-textarea :disabled="isReadOnly" v-model="propData.sumber_belajar"
        allow-clear
        :autoSize="{ minRows: 4 }"></a-textarea>
        </a-form-model-item>
        <a-form-model-item class="hsn-input-required" label="Learning Tools & Media" prop="alat_media">
          <!-- <ckeditor
            :value="propData.alat_media"
            @input="e => handleChange(e, 'alat_media')"
            :editor="editor"
            :config="editorConfig"
        ></ckeditor> -->
        <a-textarea
        :disabled="isReadOnly"
        v-model="propData.alat_media"
        allow-clear
        :autoSize="{ minRows: 4 }"></a-textarea>
        </a-form-model-item>
      </template>
      <template v-else-if="radioMenu === 'grading'">
        <a-form-model-item class="hsn-input-required" label="Attitude" prop="penilaian_sikap">
          <ckeditor
            :disabled="isReadOnly"
            :value="propData.penilaian_sikap"
            @input="e => handleChange(e, 'penilaian_sikap')"
            :editor="editor"
            :config="editorConfig"
        ></ckeditor>
        </a-form-model-item>
        <a-form-model-item class="hsn-input-required" label="Knowledge" prop="penilaian_pengetahuan">
          <ckeditor
            :disabled="isReadOnly"
            :value="propData.penilaian_pengetahuan"
            @input="e => handleChange(e, 'penilaian_pengetahuan')"
            :editor="editor"
            :config="editorConfig"
        ></ckeditor>
        </a-form-model-item>
        <a-form-model-item class="hsn-input-required" label="Skill" prop="penilaian_keterampilan">
          <ckeditor
            :disabled="isReadOnly"
            :value="propData.penilaian_keterampilan"
            @input="e => handleChange(e, 'penilaian_keterampilan')"
            :editor="editor"
            :config="editorConfig"
        ></ckeditor>
        </a-form-model-item>
      </template>
      <template v-else-if="radioMenu === 'attachment'">
        <template v-if="!isReadOnly">
          <a-row :gutter="16">
            <a-col :lg="12" :md="24">
              <a-form-model-item class="hsn-input-required" :colon="false" label="Description" prop="deskripsi_lampiran">
                <a-textarea allow-clear
                :autoSize="{ minRows: 5 }" v-model="attachment.deskripsi_lampiran"></a-textarea>
              </a-form-model-item>
            </a-col>
            <a-col :lg="12" :md="24">
              <a-form-model-item class="hsn-input-required" :colon="false" prop="file">
                <label for="label">File <small class="font-italic">(file must be pdf, max. size 2 mb)</small></label>
                <a-upload-dragger
                  accept="application/pdf"
                  :fileList="attachment.file"
                  name="lampiran"
                  :beforeUpload="uploadFile"
                  :remove="handleRemove"
                >
                  <p class="ant-upload-drag-icon">
                    <a-icon type="inbox" />
                  </p>
                  <p class="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </a-upload-dragger>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-button @click.prevent="addFile" size="large" block icon="plus">
            ADD NEW ATTACHMENT
          </a-button>
        </template>
        <div class="my-4 text-center" v-if="!newFileList.length && !fileList.length && !fileTopik.length">
          <hr />
          No Attachment Data.
        </div>
        <template v-else>
          <div :class="isReadOnly ? 'mt-5' : null">
            <div v-for="(file, i) in fileTopik" :key="i">
              <hr />
              <a-row :class="!isMobile ? ['d-flex', 'align-items-center'] : null" :gutter="16">
                <a-col :md="15" :sm="24"><strong>{{file.originalname || `Lampiran Topik ${i + 1}`}}</strong></a-col>
                <a-col :class="isMobile ? 'mt-3' : null" :md="5" :sm="24">
                  <a target="_blank" :href="`${apiUrl}/${file.filepath}/${file.filename}`" class="seeDocument">
                    <a-icon type="paper-clip" />
                    See Attachment
                  </a>
                </a-col>
              </a-row>
            </div>
            <div v-for="(file, i) in fileList" :key="file.key">
              <hr />
              <a-row :class="!isMobile ? ['d-flex', 'align-items-center'] : null" :gutter="16">
                <a-col :md="15" :sm="24"><strong>{{file.deskripsi_lampiran || `Lampiran ${i + 1}`}}</strong></a-col>
                <a-col :class="isMobile ? 'mt-3' : null" :md="5" :sm="24">
                  <a target="_blank" :href="`${apiUrl}/${file.file[0].filepath}/${file.file[0].filename}`" class="seeDocument">
                    <a-icon type="paper-clip" />
                    See Attachment
                  </a>
                </a-col>
                <a-col v-if="!isReadOnly" :md="4" :sm="24">
                  <a-button :block="isMobile" @click.prevent="deleteFile(file.key, 'old')" size="large" icon="delete">
                  </a-button>
                </a-col>
              </a-row>
            </div>
            <div v-for="file in newFileList" :key="'lampiran-baru-' + file.key">
              <hr />
              <a-row type="flex" align="middle" :gutter="16">
                <a-col :md="15" :sm="24"><strong>{{file.deskripsi_lampiran || `Lampiran Baru ${i + 1}`}}</strong></a-col>
                <a-col :md="5" :sm="24">
                  <a @click.prevent="pdfReadFile(file)" class="seeDocument">
                    <a-icon type="paper-clip" />
                    See Attachment
                  </a>
                </a-col>
                <a-col v-if="!isReadOnly" :md="4" :sm="24">
                  <a-button :block="isMobile" @click.prevent="deleteFile(file.key, 'new')" size="large" icon="delete">
                  </a-button>
                </a-col>
              </a-row>
            </div>
          </div>
        </template>
        <hr />
      </template>
    </a-form-model>
    <!--  -->
    <a-form-model :model="kegiatanPembelajaran" v-show="radioMenu === 'activity'" ref="rulesKp" :rules="rulesKp">
      <header-menu title="Preliminary"></header-menu>
      <a-row :gutter="16">
        <a-col :sm="24" :md="12">
          <a-form-model-item class="hsn-input-required" label="Time Allocation (minutes)" prop="pendahuluan_kp_alokasi_waktu">
            <a-input-number
              :disabled="isReadOnly"
              v-model="kegiatanPembelajaran.pendahuluan_kp.alokasi_waktu"
              @change="e => handleChange(e, ['pendahuluan_kp', 'alokasi_waktu'])"
              :min="1"
              size="large"
              class="w-100"></a-input-number>
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12">
          <a-form-model-item class="hsn-input-required" label="Description" prop="pendahuluan_kp_deskripsi">
            <ckeditor
              :disabled="isReadOnly"
              v-model="kegiatanPembelajaran.pendahuluan_kp.deskripsi"
              @input="e => handleChange(e, ['pendahuluan_kp', 'deskripsi'])"
              :editor="editor"
              :config="editorConfig"
          ></ckeditor>
          </a-form-model-item>
        </a-col>
      </a-row>
      <header-menu title="Core Activity"></header-menu>
      <a-row v-for="(inti, i) in kegiatanPembelajaran.inti_kp" :key="inti.key"  :gutter="16">
        <a-col :md="2" :sm="24" v-if="i !== 0">
          <a-form-model-item label=" " :colon="false">
          <div class="w-100">
            <a-button :disabled="isReadOnly" class="w-100 text-danger border border-danger" @click.prevent="deleteInti(inti.key)" size="large" icon="delete">
            </a-button>
          </div>
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="i !== 0 ? 4 : 6">
          <a-form-model-item class="hsn-input-required" label="No. Meeting">
            <a-input-number :value="inti.key" disabled size="large" class="w-100"></a-input-number>
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="6">
          <a-form-model-item class="hsn-input-required" label="Time Allocation (minutes)">
            <a-input-number :disabled="isReadOnly" @change="e => intiKpAction('change', { key: inti.key, alokasi_waktu: e })" v-model="inti.alokasi_waktu" :min="1" size="large" class="w-100"></a-input-number>
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12">
          <a-form-model-item class="hsn-input-required" label="Description">
            <ckeditor
              :disabled="isReadOnly"
              @input="e => intiKpAction('change', { key: inti.key, deskripsi: e })"
              v-model="inti.deskripsi"
              :editor="editor"
              :config="editorConfig"
          ></ckeditor>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-button v-if="kegiatanPembelajaran.inti_kp.length < 4 && !isReadOnly" @click.prevent="addNewInti" class="mb-4" size="large" block icon="plus">ADD NEW CORE</a-button>
      <!--  -->
      <header-menu title="Closing Activity"></header-menu>
      <a-row :gutter="16">
        <a-col :sm="24" :md="12">
          <a-form-model-item class="hsn-input-required"  label="Time Allocation (minutes)" prop="penutup_kp_alokasi_waktu">
            <a-input-number :disabled="isReadOnly" v-model="kegiatanPembelajaran.penutup_kp.alokasi_waktu"
              @change="e => handleChange(e, ['penutup_kp', 'alokasi_waktu'])" :min="1" size="large" class="w-100"></a-input-number>
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12">
          <a-form-model-item class="hsn-input-required" label="Description" prop="penutup_kp_deskripsi">
            <ckeditor
              :disabled="isReadOnly"
              v-model="kegiatanPembelajaran.penutup_kp.deskripsi"
              @input="e => handleChange(e, ['penutup_kp', 'deskripsi'])"
              :editor="editor"
              :config="editorConfig"
          ></ckeditor>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import moment from 'moment'
import config from '@/config'
import FileSaver from 'file-saver'
const HeaderMenu = () => import('@/components/app/Registration/Header')

export default {
  name: 'FormRPP',
  components: { HeaderMenu },
  props: {
    propData: {
      type: Object,
      required: true,
    },
    weeks: {
      type: Array,
      required: true,
    },
    subjects: {
      type: Array,
      // required: true,
    },
    levels: {
      type: Array,
      // required: true,
    },
    currentSubject: {
      // type: Number,
      required: true,
    },
    currentLevel: {
      // type: Number,
      required: true,
    },
    allRpp: {
      type: Array,
      // required: true,
    },
    loadingValidate: {
      type: Boolean,
      // required: true,
    },
    currentEditData: {
      type: Object,
    },
    newFileList: {
      type: Array,
    },
    fileList: {
      type: Array,
    },
    fileTopik: {
      type: Array,
    },
    isReadOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      radioMenu: 'detail',
      laodingTable: false,
      weekDate: [],
      attachment: {
        deskripsi_lampiran: null,
        file: [],
      },
      activity: {
        type: null,
        deskripsi_kegiatan: null,
        alokasi_waktu: null,
      },
      kegiatanPembelajaran: {
        pendahuluan_kp: {
          alokasi_waktu: null,
          deskripsi: null,
        },
        inti_kp: [
          {
            key: 1,
            alokasi_waktu: null,
            deskripsi: null,
          },
        ],
        penutup_kp: {
          alokasi_waktu: null,
          deskripsi: null,
        },
      },
      apiUrl: config.apiUrl,
    }
  },
  methods: {
    handleChange(value, column) {
      let payload = {}
      if (column === 'id_week') {
        if (!value) {
          payload = { value, column }
          this.weekDate = []
        } else {
          const dataWeek = this.weeks.find(week => week.week === value)
          this.weekDate = [moment(dataWeek.tanggal_awal, 'YYYY-MM-DD'), moment(dataWeek.tanggal_akhir, 'YYYY-MM-DD')]
          payload = {
            value: dataWeek.id,
            column,
          }
        }
      } else {
        payload = { value, column }
      }

      // console.log(payload)
      this.$emit('handle-change', payload)
    },
    uploadFile(file) {
      console.log(file)
      const isPdf = file.type === 'application/pdf'
      if (!isPdf) {
        this.$notification.error({
          message: 'UPLOAD FAILED',
          description: 'Please only upload PDF formatted document.',
        })
      } else if (file.size > 2097152) {
        this.$notification.error({
          message: 'UPLOAD FAILED',
          description: 'Please only upload PDF with maximum size 2mb.',
        })
      } else {
        this.attachment.file = [file]
      }
      // console.log(this.attachment.file)
      return false
    },
    handleRemove() {
      this.attachment.file = []
    },
    addFile() {
      if (!this.attachment.deskripsi_lampiran || !this.attachment.file.length) {
        return this.$notification.error({
          message: 'Sorry',
          description: 'Description and File is required.',
        })
      }

      this.$emit('changeFile', this.attachment)
      this.attachment = { deskripsi_lampiran: null, file: [] }
    },
    deleteFile(key, type) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to delete this file?</div>
        ),
        onOk: () => {
          this.$emit('deleteFile', { type, key })
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
        cancelText: 'Cancel',
      })
    },
    addNewInti() {
      this.kegiatanPembelajaran.inti_kp.push({
        key: this.kegiatanPembelajaran.inti_kp.length + 1,
        deskripsi: null,
        alokasi_waktu: null,
      })
      this.intiKpAction('new', this.kegiatanPembelajaran.inti_kp)
    },
    deleteInti(key) {
      this.kegiatanPembelajaran.inti_kp = this.kegiatanPembelajaran.inti_kp.filter(inti => inti.key !== key)
      this.kegiatanPembelajaran.inti_kp = this.kegiatanPembelajaran.inti_kp.map((inti, i) => {
        return {
          ...inti,
          key: i + 1,
        }
      })
      this.intiKpAction('new', this.kegiatanPembelajaran.inti_kp)
    },
    intiKpAction(action, payload) {
      this.$emit('intiKpAction', { action, payload })
    },
    async pdfReadFile(data) {
      const file = data.file[0]
      // const fReader = new FileReader()
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = () => {
        FileSaver.saveAs(new Blob([reader.result]), `${file.name}`)
      }
      reader.onerror = error => console.log(error)
    },
  },
  created() {
    if (this.currentWeek) {
      const dataWeek = this.weeks.find(week => week.week === this.currentWeek)
      if (dataWeek) {
        this.weekDate = [moment(dataWeek.tanggal_awal, 'YYYY-MM-DD'), moment(dataWeek.tanggal_akhir, 'YYYY-MM-DD')]
      }
    }

    this.kegiatanPembelajaran = {
      pendahuluan_kp: this.propData.pendahuluan_kp,
      inti_kp: this.propData.inti_kp,
      penutup_kp: this.propData.penutup_kp,
    }
  },
  computed: {
    editor() {
      return this.$store.state.ckEditor.editor
    },
    editorConfig() {
      return {
        ...this.$store.state.ckEditor.editorConfig,
        toolbar: {
          items: [
            'alignment',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            'fontColor',
            '|',
            // 'MathType',
            // 'bulletedList',
            'numberedList',
            'blockQuote',
            'link',
            'undo',
            'redo',
          ],
          shouldNotGroupWhenFull: true,
        },
      }
    },
    rules() {
      return {
        id_week: [
          {
            validator: (rule, value, callback) => {
              const weekIsExist = this.allRpp.find(rpp => rpp.week === this.currentWeek && this.currentEditData.week !== this.currentWeek)
              if (!this.currentWeek) return callback(new Error('Week is required!'))
              else if (weekIsExist) return callback(new Error('RPP on this week is already made!'))
              else callback()
            },
            trigger: 'change',
          },
        ],
        landasan_syar_i: [
          { required: true, message: "Syar'i source is required!", trigger: 'blur' },
        ],
        tujuan_pembelajaran: [
          { required: true, message: 'Learning objectives is required!', trigger: 'blur' },
        ],
        penilaian_sikap: [
          { required: true, message: 'All grading fields are required!', trigger: 'blur' },
        ],
        penilaian_pengetahuan: [
          { required: true, message: 'All grading fields are required!', trigger: 'blur' },
        ],
        penilaian_keterampilan: [
          { required: true, message: 'All grading fields are required!', trigger: 'blur' },
        ],
        sumber_belajar: [
          { required: true, message: 'Learning sources fields are required!', trigger: 'blur' },
        ],
        alat_media: [
          { required: true, message: 'Learning tools & media fields are required!', trigger: 'blur' },
        ],
        // deskripsi_lampiran: [
        //   {
        //     validator: (rule, value, callback) => {
        //       if (!value) return callback(new Error('Description is required!'))
        //       else callback()
        //     },
        //     trigger: 'change',
        //   },
        // ],
        // file: [
        //   {
        //     validator: (rule, value, callback) => {
        //       if (!this.attachment.file.length) return callback(new Error('File is required!'))
        //       else callback()
        //     },
        //     trigger: 'change',
        //   },
        // ],
      }
    },
    currentWeek() {
      const dataCurrentWeek = this.weeks.find(week => week.id === this.propData.id_week)
      // console.log(dataCurrentWeek
      if (dataCurrentWeek) {
        return dataCurrentWeek.week
      } else {
        return null
      }
    },
    rulesKp() {
      return {
        penutup_kp_alokasi_waktu: [
          {
            validator: (rule, value, callback) => {
              // console.log('masukss')
              if (!this.kegiatanPembelajaran.penutup_kp.alokasi_waktu) return callback(new Error('Time allocation is required!'))
              else callback()
            },
            trigger: 'change',
          },
        ],
        pendahuluan_kp_alokasi_waktu: [
          {
            validator: (rule, value, callback) => {
              if (!this.kegiatanPembelajaran.pendahuluan_kp.alokasi_waktu) return callback(new Error('Time allocation is required!'))
              else callback()
            },
            trigger: 'change',
          },
        ],
        penutup_kp_deskripsi: [
          {
            validator: (rule, value, callback) => {
              // console.log('masuk')
              if (!this.kegiatanPembelajaran.penutup_kp.deskripsi) return callback(new Error('Description is required!'))
              else callback()
            },
            trigger: 'change',
          },
        ],
        pendahuluan_kp_deskripsi: [
          {
            validator: (rule, value, callback) => {
              // console.log('masu2k')
              if (!this.kegiatanPembelajaran.pendahuluan_kp.deskripsi) return callback(new Error('Description is required!'))
              else callback()
            },
            trigger: 'change',
          },
        ],
      }
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
  watch: {
    loadingValidate(newVal) {
      if (newVal) {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.$refs.rulesKp.validate(valid2 => {
              if (valid2) {
                let intiIsValidate = true
                for (let i = 0; i < this.kegiatanPembelajaran.inti_kp.length; i++) {
                  const inti = this.kegiatanPembelajaran.inti_kp[i]
                  if (!inti.deskripsi || !inti.alokasi_waktu) {
                    intiIsValidate = false
                    break
                  }
                }
                if (!intiIsValidate) {
                  this.$emit('validationForm', false, false)
                  this.$notification.error({
                    message: 'Validation Failed',
                    description: 'Please check on your core activity on ACTIVITY tab input.',
                  })
                } else this.$emit('validationForm', true)
              } else {
                this.$emit('validationForm', false)
              }
            })
          } else {
            this.$emit('validationForm', false)
            // this.$notification.error({
            //   message: 'Maaf',
            //   description: 'Ada data wajib yang belum terisi/sesuai. Periksa kembali.',
            // })
            // return false
          }
        })
      }
    },
    'propData.id_week'(newVal) {
      if (newVal) {
        this.kegiatanPembelajaran = {
          pendahuluan_kp: this.propData.pendahuluan_kp,
          inti_kp: this.propData.inti_kp,
          penutup_kp: this.propData.penutup_kp,
        }
        const dataWeek = this.weeks.find(week => week.id === newVal)
        this.weekDate = [moment(dataWeek.tanggal_awal, 'YYYY-MM-DD'), moment(dataWeek.tanggal_akhir, 'YYYY-MM-DD')]
      } else {
        this.kegiatanPembelajaran = {
          pendahuluan_kp: {
            alokasi_waktu: null,
            deskripsi: '',
          },
          inti_kp: [
            {
              key: 1,
              alokasi_waktu: null,
              deskripsi: '',
            },
          ],
          penutup_kp: {
            alokasi_waktu: null,
            deskripsi: '',
          },
        }
      }
    },
  },
}
</script>

<style lang="scss">
.form-rpp {
  .ck-editor__editable_inline {
      min-height: 100px
  }

  // .hsn-input-required .ant-form-item-label label::before {
  //   display: inline-block;
  //   margin-right: 4px;
  //   color: #f5222d;
  //   font-size: 14px;
  //   font-family: SimSun, sans-serif;
  //   line-height: 1;
  //   content: '*';
  // }
  .seeDocument {
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
